/* eslint-disable react/destructuring-assignment */
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { PropTypes } from "prop-types";
import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Link from "react-router-dom/Link";
import styles from "../app.module.css";
import Toast from "../components/Toast/Toast";
import theme from "../theme";
import { getLogoForClient } from "../utils";

@inject("authStore")
@observer
class LoginPage extends React.Component {
  @observable username = "";

  @observable password = "";

  @observable isIE = false;

  @observable errorText = false;

  @observable toastShown = false;

  @observable toastStatus = false;

  constructor(props) {
    super(props);

    const { authStore } = this.props;
    if (authStore.accessToken) {
      authStore.setAccessToken(undefined);
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const { authStore, history } = this.props;
    try {
      await authStore.login(this.username, this.password);
      const res = await authStore.fetchCompanyDetails();
      if (res.data.branche && res.data.branche === "FileSharing") {
        history.replace("/fileupload");
      } else {
        history.replace(authStore.redirectRouteAfterAuth);
      }
    } catch (error) {
      this.errorText = "Falscher Benutzername oder Passwort";
    }
  };

  componentDidMount() {
    const isInternetExplorer = false || !!document.documentMode;
    const { location } = this.props;

    if (location?.state?.data?.error?.expired) {
      this.toastShown = true;
      this.toastStatus = {
        type: "error",
        title: "Achtung",
        body: "Session abgelaufen, bitte melden Sie sich erneut an",
      };
    }

    this.isIE = isInternetExplorer;
  }

  handleUserChange = (e) => (this.username = e.target.value);

  handlePasswordChange = (e) => (this.password = e.target.value);

  render() {
    const { error, isLoading } = this.props.authStore;

    const config = theme[process.env.REACT_APP_CLIENT];
    const logo = getLogoForClient()[process.env.REACT_APP_CLIENT];

    return (
      <div style={{ width: "470px", paddingTop: "200px" }} className='mx-auto'>
        <img className='w-75 mx-auto d-block' src={logo} alt='Wohnbaugruppe' />
        <p className='text-center'>{config.title}</p>
        <Card className='shadow'>
          <div className={styles.element}>
            <h3>Login</h3>
          </div>
          <Card.Body>
            <Form onSubmit={this.onSubmit} className={`${styles["form-signin"]} pb-3`}>
              <Form.Group>
                <Form.Control
                  id='username'
                  type='text'
                  placeholder='Username'
                  name='username'
                  value={this.username}
                  onChange={this.handleUserChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  id='password'
                  placeholder='Passwort'
                  type='password'
                  name='password'
                  value={this.password}
                  onChange={this.handlePasswordChange}
                />
                <Link className={styles["forgot-password"]} to='/login/reset'>
                  Passwort vergessen?
                </Link>
                {error && <small className='text-danger'>{this.errorText}</small>}
              </Form.Group>

              <Form.Group>
                <Button
                  type='submit'
                  variant='primary'
                  style={{ background: config.color }}
                  disabled={isLoading || this.isIE}
                  block
                >
                  Anmelden
                </Button>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        <Toast show={this.toastShown} onHide={() => (this.toastShown = false)} status={this.toastStatus} />
      </div>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
  authStore: PropTypes.any.isRequired,
};

export default withRouter(LoginPage);
