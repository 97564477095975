import { inject, observer } from "mobx-react/index";
import { PropTypes } from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Header from "../components/Header/Header";
import LoadingSpinnerContainer from "../components/LoadingSpinner/LoadingSpinnerContainer";
import OrderTable from "../components/OrderTable";

@inject("dataStore")
@observer
class OrdersPage extends React.Component {
  static propTypes = {
    dataStore: PropTypes.any.isRequired,
  };

  componentDidMount() {
    const { dataStore } = this.props;
    dataStore.loadOrders();
  }

  render() {
    const { dataStore } = this.props;

    return (
      <div>
        <Header />
        <LoadingSpinnerContainer loading={!dataStore.orders}>
          {() => (
            <Container style={{ maxWidth: "1600px" }}>
              <Row className='py-3'>
                <Col>
                  <h1 className='text-center'>Alle Aufträge</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <OrderTable data={dataStore.orders.toJS()} stickyOffset={60} />
                </Col>
              </Row>
            </Container>
          )}
        </LoadingSpinnerContainer>
      </div>
    );
  }
}

export default OrdersPage;
