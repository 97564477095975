/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger,arrow-body-style */
import { observer } from "mobx-react/index";
import { observable } from "mobx/lib/mobx";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { withRouter } from "react-router-dom";
import Sticky from "react-stickynode";
import { v4 as uuidv4 } from "uuid";
import { DataTable } from "./DataTable/DataTable";
import withBreakpoint from "./withBreakpoint/withBreakpoint";

@observer
class OrderTable extends Component {
  @observable filterByObjectID;

  handleRowClick = (e, row) => {
    const { history } = this.props;
    localStorage.setItem("lastViewedItem", row.auftr_id);
    history.push(`/orders/${row.auftr_id}`);
  };

  render() {
    const { data, breakpoint } = this.props;

    const defaultSorted = [
      {
        dataField: "gueltig_ab",
        order: "desc",
      },
    ];
    const columns = [
      {
        dataField: "uid",
        text: "UID",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: "10px" };
        },
        align: "center",
      },
      {
        dataField: "zustaendig.text",
        text: "Zuständig",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: "10px" };
        },
        align: "center",
      },
      {
        dataField: "beschr",
        text: "Beschreibung",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: "10px" };
        },
        align: "center",
        formatter: (cell) => (cell && cell.length > 30 ? `${cell.substring(0, 27)}...` : cell),
      },
      {
        dataField: "auftr_nr",
        text: "Auftragsnummer",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: "10px" };
        },
        align: "center",
        formatter: (cell) => (cell && cell.length > 30 ? `${cell.substring(0, 27)}...` : cell),
      },
      {
        dataField: "gueltig_ab",
        text: "Auftragsdatum",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: "10px" };
        },
        align: "center",
      },
      {
        dataField: "mandant",
        text: "Mandant",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
        align: "center",
      },
      {
        dataField: "obj_nr",
        text: "ObjektNr",
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      {
        dataField: "adr",
        text: "Objekt-Adresse",
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      {
        dataField: "kurzbzg",
        text: "Tätigkeit",
        headerAlign: "center",
        sort: true,
        align: "center",
        headerStyle: () => {
          return { width: "10%" };
        },
      },
      {
        dataField: "statustitle",
        text: "Status",
        headerAlign: "center",
        sort: true,
        align: "center",
        headerStyle: () => {
          return { width: "10%" };
        },
      },
      {
        dataField: "uuid", // This field is not in the dataset
        text: "UUID",

        headerAlign: "center",
        align: "center",
        formatter: (cell, row) => {
          // You can generate a UUID here or use a static one
          return uuidv4(); // Generate a new UUID for each row
        },
        headerStyle: () => {
          return { width: "150px", display: "none" };
        },
        classes: "hidden",
        style: { display: "none" },
      },
    ];

    const extraFilters = [
      // eslint-disable-next-line react/jsx-key
      <Form.Control as='select' onChange={(e) => this.filterByObjectID(e.target.value)} id='nation' defaultValue=''>
        <option value=''>Objekt auswählen</option>
        {[...new Set(data.map((e) => e.obj_nr))]
          .sort((a, b) => a - b)
          .map((e, i) => (
            <option key={i} value={e}>
              {e}
            </option>
          ))}
      </Form.Control>,
    ];

    return (
      <DataTable
        data={data}
        columns={columns}
        keyField='uuid'
        defaultSorted={defaultSorted}
        extraFilters={extraFilters}
        onRowClick={this.handleRowClick}
        columnToggle
        searchCheckResponsible
        filterWrapper={Sticky}
        statusToggleActive
        filterWrapperProps={{
          top: 60,
          enabled: breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md",
        }}
      />
    );
  }
}

OrderTable.propTypes = {
  data: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
};

export default withBreakpoint(withRouter(OrderTable));
