import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faEye,
  faChevronDown,
  faFilePdf,
  faFileUpload,
  faFileDownload,
  faFileImage,
  faTrashAlt,
  faExclamation,
  faEnvelope,
  faFileWord,
  faFileAlt,
  faFileCode,
  faPlus,
  faPlusCircle,
  faFileExcel,
  faCopy,
  faFilePowerpoint,
  faFileArchive,
  faUserSlash,
  faBell,
  faUndo,
  faHeart,
  faMinus,
  faUserFriends,
  faShare,
  faClock,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";
import OrdersPage from "./OrdersPage";
import LoginPage from "./LoginPage";
import OrderDetailsPage from "./OrderDetailsPage";
import ObjectPage from "./ObjectsPage";
import ObjectFilesPage from "./ObjectFilesPage";
import ArchivePage from "./ArchivePage";
import PrivateRoute from "../helpers/PrivateRoute";
import NotFoundPage from "./NotFoundPage";
import RequestPasswordResetPage from "./RequestPasswordResetPage";
import ResetPasswordPage from "./ResetPasswordPage";
import ChangePasswordPage from "./ChangePasswordPage";
import FileUploadPage from "./FileUploadPage";
import ObjectZipDownloadPage from "./ObjectZipDownloadPage";
import HistoryObjectsPage from "./HistoryObjectsPage";
import theme from "../theme";

// import PrivateRoute from '../helpers/PrivateRoute';

library.add(
  faEdit,
  faEye,
  faChevronDown,
  faFilePdf,
  faFileImage,
  faFileUpload,
  faFileDownload,
  faTrashAlt,
  faEnvelope,
  faExclamation,
  faFileWord,
  faFileAlt,
  faFileCode,
  faFileExcel,
  faCopy,
  faFilePowerpoint,
  faPlus,
  faPlusCircle,
  faFileArchive,
  faUserSlash,
  faUndo,
  faBell,
  faHeart,
  faMinus,
  faUserFriends,
  faShare,
  faClock,
  faFileSignature
);

export default class App extends React.Component {
  updateFavicon = (url) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = url;

    // If the link element doesn't exist, append it to the head
    if (!document.querySelector("link[rel*='icon']")) {
      document.head.appendChild(link);
    }
  };

  componentDidMount() {
    const { favicon } = theme[process.env.REACT_APP_CLIENT];
    if (favicon) this.updateFavicon(favicon);
  }

  render() {
    const config = theme[process.env.REACT_APP_CLIENT];
    if (config.color)
      document.documentElement.style.setProperty("--primary-color", config.color ? config.color : "#ea2210");
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/login' exact component={LoginPage} />
          <Route path='/login/reset' exact component={RequestPasswordResetPage} />
          <Route path='/login/reset/:token' exact component={ResetPasswordPage} />
          <PrivateRoute path='/login/change' exact component={ChangePasswordPage} />
          <PrivateRoute exact path='/'>
            <Redirect to='/orders' />
          </PrivateRoute>
          <PrivateRoute exact path='/fileupload' component={FileUploadPage} />
          <PrivateRoute exact path='/orders' component={OrdersPage} />
          <PrivateRoute path='/orders/:orderId' component={OrderDetailsPage} />
          <PrivateRoute exact path='/objects' component={ObjectPage} />
          <PrivateRoute exact path='/objects/:objId/files/history' component={HistoryObjectsPage} />
          <PrivateRoute path='/objects/:objId/files' component={ObjectFilesPage} />
          <PrivateRoute path='/objects/:objId/zip/:files' component={ObjectZipDownloadPage} />
          <PrivateRoute path='/archive' component={ArchivePage} />
          <Route path='/error' component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}
