import { observable, toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { PropTypes } from "prop-types";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { Comparator } from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import style from "./DataTable.module.scss";
import DataTableColumnToggle from "./DataTableColumnToggle";
import DataTablePagination from "./DataTablePagination";
import StatusColumnToggle from "./StatusColumnToggle";

@inject("dataStore")
@observer
export class DataTable extends React.Component {
  @observable filteredData = [];

  @observable localSearchText = "";

  @observable page = 1;

  @observable sizePerPage = 10;

  @observable searchResponsible = false;

  @observable statusToggles = {
    Offen: true,
    "In Arbeit": true,
    "Auftrag abgeschlossen": true,
    Angenommen: true,
    Abgelehnt: true,
  };

  handleTableChange = (type, { searchText, filters, special }) => {
    const { data, columns, statusToggleActive } = this.props;

    if (type === "search") {
      this.localSearchText = searchText;
      localStorage.setItem("objectSearchText", searchText);
    }

    if (special) {
      const finalArray = this.getRevision(data);

      this.filteredData = observable.array(finalArray);
    } else if (type === "search" || type === "filter") {
      if (
        (type === "search" && searchText === "") ||
        (type === "filter" && filters.name === undefined && !filters.filterVal)
      ) {
        return (this.filteredData = observable.array(data).filter((row) => {
          if (statusToggleActive) {
            let statusItems = localStorage.getItem("statusFields");
            if (!statusItems) statusItems = [];
            else {
              statusItems = JSON.parse(statusItems);
            }

            if (statusItems.includes(row.statustitle)) return false;
          }
          return true;
        }));
      }

      this.filteredData = observable.array(
        data
          .filter((row) => {
            if (statusToggleActive) {
              let statusItems = localStorage.getItem("statusFields");
              if (!statusItems) statusItems = [];
              else {
                statusItems = JSON.parse(statusItems);
              }

              if (statusItems.includes(row.statustitle)) return false;
            }
            return true;
          })
          .filter((row) => {
            if (this.searchResponsible) {
              if (row.zustaendig && row.zustaendig.text.toLowerCase().trim() === searchText.toLowerCase().trim()) {
                return true;
              }
              return false;
            }
            const dataFields = columns.map((e) => e.dataField);
            for (let i = 0; i < dataFields.length; i += 1) {
              const cell = dataFields[i].split(".").reduce((acc, part) => acc && acc[part], row);
              if (cell && cell.toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
                return true;
              }
            }
            return false;
          })

          .filter((row) => {
            let valid = true;
            if (type === "filter") {
              valid = false;
            }
            const filterKeys = Object.keys(filters);

            for (let i = 0; i < filterKeys.length; i += 1) {
              const filterKey = filterKeys[i];
              const { filterVal, filterType, comparator } = filters[filterKey];
              if (filterType === "TEXT") {
                if (comparator === Comparator.LIKE) {
                  valid = row[filterKey].toString().indexOf(filterVal) > -1;
                } else if (comparator === Comparator.EQ) {
                  valid = row[filterKey].toString() === filterVal;
                } else {
                  console.error(`Unhandled filter comparator: ${comparator}`);
                }
              }
              if (!valid) break;
            }
            return valid;
          })
      );
      this.page = 1;
    }
  };

  constructor(props) {
    super(props);
    const { data } = props;
    this.filteredData = observable.array(data);
  }

  componentDidMount() {
    const lastViewedItem = localStorage.getItem("lastViewedItem") || "";

    if (lastViewedItem && Number.isNaN(Number(lastViewedItem))) {
      localStorage.setItem("lastViewedItem", "");
    }

    this.localSearchText = localStorage.getItem("objectSearchText") || "";
    this.handleTableChange("search", { searchText: this.localSearchText, filters: {} });
    this.sizePerPage = localStorage.getItem("paginationSize");

    if (this.filteredData.length / this.sizePerPage >= localStorage.getItem("currentPage")) {
      this.page = Number(localStorage.getItem("currentPage")) || 1;
    } else {
      this.page = 1;
    }
  }

  setCheckbox = () => {
    this.searchResponsible = !this.searchResponsible;

    localStorage.setItem("isChecked", this.searchResponsible);
    this.handleTableChange("search", { searchText: this.localSearchText, filters: {} });
  };

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (data !== prevProps.data) {
      this.filteredData = observable.array(data);
      this.searchResponsible = localStorage.getItem("isChecked") && localStorage.getItem("isChecked") !== "false";

      this.localSearchText = localStorage.getItem("objectSearchText") || "";

      this.handleTableChange("search", { searchText: this.localSearchText, filters: {} });
      if (this.filteredData.length / this.sizePerPage >= localStorage.getItem("currentPage")) {
        this.page = Number(localStorage.getItem("currentPage")) || 1;
      } else {
        this.page = 1;
      }
    }
  }

  render() {
    const {
      columns,
      keyField,
      defaultSorted,
      extraFilters,
      onRowClick,
      onRowSelected,
      onSelectAllRows,
      search,
      searchCheckResponsible,
      pagination,
      pageSize,
      bordered,
      headerClasses,
      columnToggle,
      selectedFiles,
      filterWrapper: FilterWrapper,
      filterWrapperProps,
      obj_id,
      companies,
      statusToggleActive,
    } = this.props;

    const { SearchBar } = Search;

    const options = {
      custom: true,
      page: this.page,
      sizePerPage: pagination ? pageSize : this.filteredData.length,
      totalSize: this.filteredData.length,
    };

    if (this.sizePerPage) {
      options.sizePerPage = this.sizePerPage;
    }
    const paginationSize = localStorage.getItem("paginationSize");
    this.searchResponsible = localStorage.getItem("isChecked") && localStorage.getItem("isChecked") !== "false";

    if (paginationSize) {
      options.sizePerPage = Number(paginationSize);
    }

    const rowEvents = onRowClick
      ? {
          onClick: onRowClick,
        }
      : null;

    const rowStyle = rowEvents ? { cursor: "pointer" } : null;

    const selectRow = {
      mode: "checkbox",
      onSelect: onRowSelected,
      onSelectAll: onSelectAllRows,
    };

    for (let i = 0; i < columns.length; i += 1) {
      const col = columns[i];
      if (col.attrs) {
        col.attrs["data-header"] = col.text;
      } else {
        col.attrs = { "data-header": col.text };
      }
    }

    const rowClasses = (row) => {
      let classes = "null";

      const lastViewedItem = localStorage.getItem("lastViewedItem");
      if (lastViewedItem && !Number.isNaN(Number(lastViewedItem))) {
        if (row && row.auftr_id === Number(lastViewedItem)) {
          classes = "highlight";
        }
      }

      return classes;
    };

    const onChangeStatusFilter = (status) => {
      this.statusToggles[status] = !this.statusToggles[status];
      this.handleTableChange("search", { searchText: this.localSearchText, filters: {} });
    };

    return (
      <div>
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField={keyField}
              columns={columns}
              data={toJS(this.filteredData)}
              search
              isKey
              columnToggle
              defaultSorted={defaultSorted}
            >
              {(toolkitprops) => (
                <div>
                  {(search || columnToggle || extraFilters) && (
                    <FilterWrapper {...filterWrapperProps}>
                      <Row className='justify-content-end pb-2'>
                        {search && (
                          <Col xs='6' lg='3'>
                            <SearchBar
                              {...toolkitprops.searchProps}
                              searchText={this.localSearchText}
                              placeholder='Suchen'
                              className='searchbar'
                            />
                            {searchCheckResponsible && (
                              <Form.Check
                                type='checkbox'
                                label='nur Zuständige filtern'
                                checked={this.searchResponsible}
                                onChange={() => this.setCheckbox()}
                              />
                            )}
                          </Col>
                        )}
                        {columnToggle && (
                          <Col lg='auto' className='d-none d-lg-block'>
                            <DataTableColumnToggle {...toolkitprops.columnToggleProps} />
                          </Col>
                        )}
                        {statusToggleActive && (
                          <Col lg='auto' className='d-none d-lg-block'>
                            <StatusColumnToggle
                              toggles={this.statusToggles}
                              onChangeStatusFilter={onChangeStatusFilter}
                            />
                          </Col>
                        )}
                      </Row>
                    </FilterWrapper>
                  )}
                  <BootstrapTable
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                    hover
                    filter={filterFactory()}
                    rowEvents={rowEvents}
                    rowStyle={rowStyle}
                    {...(onRowSelected ? { selectRow } : null)}
                    bordered={bordered}
                    headerClasses={headerClasses}
                    bootstrap4
                    remote={{ search: true, filter: true }}
                    onTableChange={this.handleTableChange}
                    wrapperClasses={style.dataTable}
                    rowClasses={rowClasses}
                  />
                  {pagination && (
                    <DataTablePagination
                      paginationProps={paginationProps}
                      obj_id={obj_id}
                      companies={companies}
                      selectedFiles={selectedFiles}
                      onPageChange={(page) => {
                        this.page = page;
                        localStorage.setItem("currentPage", page);
                      }}
                      onSizePerPageChange={(pageSizes) => (this.sizePerPage = pageSizes)}
                    />
                  )}
                </div>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </div>
    );
  }
}

export const dateFormatter = (cell) => <span>{new Date(cell).toLocaleDateString("de-DE")}</span>;

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  filter: PropTypes.any,
  columns: PropTypes.array.isRequired,
  keyField: PropTypes.string.isRequired,
  defaultSorted: PropTypes.array.isRequired,
  extraFilters: PropTypes.node,
  onRowClick: PropTypes.func,
  onRowSelected: PropTypes.func,
  onSelectAllRows: PropTypes.func,
  search: PropTypes.bool,
  searchCheckResponsible: PropTypes.bool,
  pagination: PropTypes.bool,
  bordered: PropTypes.bool,
  headerClasses: PropTypes.string,
  columnToggle: PropTypes.bool,
  filterWrapper: PropTypes.any,
  filterWrapperProps: PropTypes.any,
  pageSize: PropTypes.number,
  selectedFiles: PropTypes.any,
  obj_id: PropTypes.string,
  companies: PropTypes.any,
  specialFilter: PropTypes.any,
  dataStore: PropTypes.any.isRequired,
  statusToggleActive: PropTypes.bool,
};

DataTable.defaultProps = {
  extraFilters: null,
  filter: "",
  onRowClick: null,
  searchCheckResponsible: false,
  onRowSelected: null,
  onSelectAllRows: null,
  search: true,
  pagination: true,
  pageSize: 10,
  bordered: true,
  headerClasses: style.header,
  columnToggle: false,
  filterWrapper: "div",
  filterWrapperProps: null,
  selectedFiles: [],
  obj_id: "0",
  companies: [],
  specialFilter: false,
  statusToggleActive: false,
};
