import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { PropTypes } from "prop-types";
import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import "url-search-params-polyfill";

@inject("authStore")
@inject("dataStore")
@observer
class PrivateRoute extends React.Component {
  @observable isLoading = true;

  componentDidMount() {
    const {
      authStore,
      location,
      dataStore,
      history,
      match: {
        params: { orderId },
      },
      computedMatch: {
        params: { orderId: computedOrderId },
      },
    } = this.props;

    if (location.search !== "") {
      const query = new URLSearchParams(location.search);
      const token = query.get("token");
      const accessToken = query.get("accessToken");

      const currentOrderId = orderId ?? computedOrderId;

      if (token) {
        authStore.setFirmId(undefined);
        authStore.setAccessToken(undefined);
        authStore.setRefreshToken(token);
        authStore.tokenLogin(token);
        this.isLoading = false;
      }
      if (accessToken) {
        if (authStore.accessToken) {
          if (currentOrderId) {
            const result = dataStore
              .loadOrderDetails(currentOrderId)
              .catch((e) => {
                if (e?.response?.status === 401) {
                  authStore.setAccessToken(undefined);
                  authStore.setRefreshToken(undefined);
                  authStore.setFirmId(undefined);
                  authStore.isAuthenticated = false;
                  authStore.isLoading = false;
                  authStore.companyDetails = null;
                  authStore.companyBranche = null;
                }
                authStore.setFirmId(undefined);
                authStore.setRefreshToken(undefined);
                authStore.setAccessToken(accessToken);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        } else {
          authStore.setAccessToken(accessToken);
          authStore.getLogin(accessToken);
          this.isLoading = false;
        }
      }
    } else {
      this.isLoading = false;
    }
  }

  render() {
    const { authStore, history, location, ...restProps } = this.props;

    if (authStore.companyBranche !== "undefined" && authStore.companyBranche === "FileSharing") {
      if (location.pathname !== "/fileupload") {
        return <Redirect to='/fileupload' />;
      }
    } else if (authStore.companyBranche !== "undefined" && authStore.companyBranche === "Noshare") {
      if (location.pathname === "/fileupload") {
        return <Redirect to='/error' />;
      }
    }
    if (!this.isLoading) {
      if (authStore.refreshToken || authStore.accessToken) {
        return <Route {...restProps} />;
      }
      authStore.setRedirectRouteAfterAuth(history.location.pathname);
      return <Redirect to='/login' />;
    }
    return <div />;
  }
}

PrivateRoute.propTypes = {
  authStore: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.any,
};

PrivateRoute.defaultProps = {
  location: null,
};

export default withRouter(PrivateRoute);
