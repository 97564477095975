/* eslint-disable arrow-body-style,no-trailing-spaces,no-debugger,no-restricted-syntax,class-methods-use-this */
import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import cachedFaults from "../mocking/faults.json";
import cachedObjects from "../mocking/objects.json";
import cachedOrderDetails from "../mocking/orderDetails.json";
import cachedOrders from "../mocking/orders.json";
import authStore from "../stores/AuthStore";

class ApiService {
  enableMocking = false;

  constructor() {
    axios.interceptors.request.use(
      (config) => {
        if (authStore.access_token) {
          // eslint-disable-next-line no-param-reassign
          config.headers = {
            Authorization: `Bearer ${authStore.accessToken}`,
          };
        }

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const response = await this.refreshToken();
          if (response) {
            const { data } = response;
            if (data.access_token) authStore.setAccessToken(data.access_token);
            if (data.refresh_token) authStore.setRefreshToken(data.refresh_token);
            if (data.login.firm_id.length > 0) authStore.setFirmId(data.login.firm_id[0]);

            axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
            originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
            return axios(originalRequest);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async refreshToken() {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auth/token`;
    const config = {
      refresh_token: authStore.refreshToken,
      grant_type: "refresh_token",
      type: "company",
    };
    if (authStore.refreshToken) return axios.post(proxyurl, config);
  }

  async tokenLogin(token) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auth/token`;
    const config = {
      refresh_token: token,
      grant_type: "refresh_token",
      type: "company",
    };
    return axios.post(proxyurl, config);
  }

  async getLogin(accessToken) {
    const proxyurl = `${process.env.REACT_APP_API_URL}wowis/api/login`;
    const token = `Bearer ${accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
      },
    });
  }

  async login(username, password) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auth/token`;

    const user = {
      username,
      password,
      grant_type: "password",
      type: "company",
    };

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200, {
        token: "fake.ODgyMQ==.token",
        success: true,
        msg: "logged in",
      });
    }

    return axios.post(proxyurl, user);
  }

  getOrders() {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auftrag`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200, cachedOrders);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  getOrderDetails(orderId) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auftrag/${orderId}`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200, cachedOrderDetails);
    }
    let response = null;
    if (orderId) {
      const token = `Bearer ${authStore.accessToken}`;
      response = axios.get(proxyurl, {
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true",
          pragma: "no-cache",
        },
      });
    }
    return response;
  }

  getFaults(orderId) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/mangel/${orderId}`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200, cachedFaults);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  getObjects() {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/objekt/firm`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200, cachedObjects);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  getPauschaleEntries() {
    const proxyurl = `${process.env.REACT_APP_API_URL}wowis/api/auftrag/mandant/1/pauschalen`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200, cachedObjects);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  setOrderStatus(orderId, status) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auftrag/${orderId}/status`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, status, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  setPauschale(orderId, pauschaleNumber) {
    const proxyurl = `${process.env.REACT_APP_API_URL}wowis/api/auftrag/${orderId}/pauschale/${pauschaleNumber}`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  setFileProperties(file_id, expiryDate) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/firma/files/${file_id}/properties`;

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(
      proxyurl,
      {
        expiry: expiryDate,
      },
      {
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true",
          pragma: "no-cache",
        },
      }
    );
  }

  addOrderComment(orderId, commentDatetime, commentText, commentDocuments) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auftrag/${orderId}/kommentar`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(
      proxyurl,
      {
        datum: commentDatetime.toISOString(),
        text: commentText,
        dokumente: commentDocuments,
      },
      {
        headers: {
          Authorization: token,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true",
          pragma: "no-cache",
        },
      }
    );
  }

  uploadRepairJobs(orderId, repairJobData, signatureData, attachments) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auftrag/${orderId}/repauftrag`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const uploadRepairData = JSON.parse(JSON.stringify(repairJobData.repairItems));
    // const uploadRepairData = [...repairJobData.repairItems];

    const repArray = uploadRepairData.map((repItem) => {
      const item = repItem;
      if (item.rep_id === "") {
        delete item.rep_id;
      }
      item.ausfuehrungsdatum = new Date(repItem.ausfuehrungsdatum).toISOString().slice(0, 10);
      // if (item.kosten === "") {
      delete item.kosten;
      // }
      delete item.id;
      item.heizungstechniker = item.heizungstechniker ? 1 : 0;

      return item;
    });

    const repData = {
      beginn: repairJobData.date,
      arbeit: repArray,
      anhang: attachments,
      name: repairJobData.signatureText,
      unterschrift: signatureData,
    };

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, repData, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  getUploadFilesPerCompany() {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/firma`;

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  getObjectFiles(obj_id) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  getCompaniesWithAccess(obj_id, file_id) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/file/${file_id}/ownerFreigabe`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  saveCompanieswithAccess(obj_id, file_id, fileArray) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/file/${file_id}/ownerFreigabe`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, fileArray, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  downloadUploadFile(file_id, onProgress) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/firma/files/${file_id}`;

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
      responseType: "blob",
      onDownloadProgress: (e) => onProgress && onProgress(Math.round((e.loaded * 100) / e.total)),
    });
  }

  downloadObjectFile(obj_id, file_id, onProgress) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/file/${file_id}`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
      responseType: "blob",
      onDownloadProgress: (e) => onProgress && onProgress(Math.round((e.loaded * 100) / e.total)),
    });
  }

  deleteObjectFile(obj_id, file_id) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/file/${file_id}`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.delete(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  getDownloadedFirms(obj_id, file_id) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/file/${file_id}/downloadedFirms`;

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  deleteUploadFile(file_id) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/firma/files/${file_id}`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.delete(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  async uploadFileWithoutObject(file, onProgress) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/firma/file`;

    const formData = new FormData();
    formData.append("name", file.name);
    formData.append("newfile", file);

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, formData, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        "Content-Type": "multipart/form-data",
        pragma: "no-cache",
      },
      onUploadProgress: (e) => onProgress && onProgress(Math.round((e.loaded * 100) / file.size)),
    });
  }

  async uploadObjectFile(obj_id, filename, file, onProgress) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}`;

    const formData = new FormData();
    formData.append("name", filename);
    formData.append("newfile", file);

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }
    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
      onUploadProgress: (e) => onProgress && onProgress(Math.round((e.loaded * 100) / file.size)),
    });
  }

  uploadInvoice(filename, doc, data, orderId) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auftrag/${orderId}/rechnung`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    let invoiceDocument;
    if (filename.toLowerCase().endsWith(".pdf")) {
      invoiceDocument = {
        doc,
        group: data.group.toString() || "0",
        re_nr: data.re_nr || "",
        dok_eingangsdat: data.dok_eingangsdat,
        umsatz: parseFloat(data.umsatz, 10) || 0,
        steuer: parseFloat(data.steuer, 10) || 0,
      };
    } else {
      invoiceDocument = {
        doc,
        group: data.group.toString() || "0",
      };
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, invoiceDocument, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  uploadDocument(document) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument`;

    const formData = new FormData();
    formData.append("name", document.name);
    formData.append("newfile", document);

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getCompany(firmId) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/firma/${firmId}`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  getObjectDetails(objectId) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/objekt/details/${objectId}`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  getReadUsers(objectId, fileIds) {
    const proxyurl = `${
      process.env.REACT_APP_API_URL
    }api/dokument/austausch/${objectId}/readUsers?fileIds=${fileIds.join(",")}`;

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  sendEmailNotification(companies, man_id, obj_nr, obj_id, message, subject, file_id, attachments) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/notify?manId=${man_id}&objNr=${obj_nr}`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200);
    }

    const formData = new FormData();
    formData.append("companyIds", companies);
    formData.append("content", message);
    formData.append("fileIds", file_id);
    formData.append("subject", subject);
    if (attachments.length > 0) {
      attachments.map((attachment) => {
        return formData.append("attachmentFiles", attachment);
      });
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, formData, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  sendFilePerEmail(obj_id, file_ids) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/notify`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200);
    }

    const data = {
      fileIds: [file_ids],
    };

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, data, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  sendCompanyEmailNotification(file_id, emails, body) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/firma/files/${file_id}/notify`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200);
    }
    const data = {
      receipients: emails.toJS(),
      emailBody: body,
    };

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, data, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  getCompaniesPerObject(man_id, obj_id) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/firma?manid=${man_id}&objnr=${obj_id}`;
    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  downloadAllFiles(selectedFiles, obj_id) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/zipDownload`;
    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, selectedFiles, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      responseType: "blob",
    });
  }

  sendPasswordResetLink(username) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auth/password/reset?user=${username}`;
    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onGet(proxyurl).reply(200);
    }

    return axios.get(proxyurl, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  setNewPassword(oldPassword, newPassword) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auth/password`;

    const data = {
      newPassword,
      oldPassword,
    };

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, data, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  resetPassword(username, password, token) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auth/password/reset`;

    const data = {
      username,
      password,
      token,
    };

    return axios.post(proxyurl, data, {
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  getFileNotifications() {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/fileNotifications`;

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  acknowledgeFileNotification(notificationId) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/fileNotifications/${notificationId}/acknowledge`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, null, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  getAcknowledgedFirms(obj_id, file_id) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/file/${file_id}/notificationAcknowledgedFirms`;

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  addFavoriteGroup(groupName, obj_id) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/firmGroup`;

    const data = {
      groupName,
    };

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, data, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  getFavoriteGroups(obj_id) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/firmGroup`;

    const token = `Bearer ${authStore.accessToken}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  deleteFavoriteGroup(groupId, obj_id) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/firmGroup/${groupId}`;

    const token = `Bearer ${authStore.accessToken}`;
    return axios.delete(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  addFirmToFavoriteGroup(groupId, obj_id, firmId) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/firmGroup/${groupId}/firm`;

    const data = {
      firmId,
    };

    const token = `Bearer ${authStore.accessToken}`;
    return axios.post(proxyurl, data, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  deleteFirmPerFavoriteGroup(groupId, obj_id, firmId) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/dokument/austausch/${obj_id}/firmGroup/${groupId}/firm/${firmId}`;

    const token = `Bearer ${authStore.accessToken}`;
    return axios.delete(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
        pragma: "no-cache",
      },
    });
  }

  uploadResponsiblePerson(personToUpload, orderId) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/auftrag/${orderId}/zustaendig`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const zustaendig = {
      text: personToUpload,
    };

    const token = `Bearer ${authStore.token}`;
    return axios.put(proxyurl, zustaendig, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  getLogData(from, to, objId) {
    const proxyurl = `${process.env.REACT_APP_API_URL}api/zugriffsLog/dokumentenaustausch/objekt/${objId}/ownerFreigabe?from=${from}&to=${to}`;

    if (this.enableMocking) {
      const mock = new MockAdapter(axios, { delayResponse: 1000 });
      mock.onPost(proxyurl).reply(200);
    }

    const token = `Bearer ${authStore.token}`;
    return axios.get(proxyurl, {
      headers: {
        Authorization: token,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }
}

export default new ApiService();
